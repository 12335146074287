import React from 'react';
import ReactDOM from 'react-dom';

import withErrorBoundary from '../withErrorBoundary';
import ManifestContainer from './ManifestContainer';
import ManifestCartonContainer from './ManifestCartonContainer';

const element = document.querySelector('.react-manifest');
const elementCarton = document.querySelector('.react-manifest-carton');

if (element) {
  ReactDOM.render(
    React.createElement(withErrorBoundary(ManifestContainer), {
      manifestId: Number(element.dataset.manifestId),
      concludeClassificationUrl: element.dataset.concludeClassificationUrl
    }),
    element
  );
}

if (elementCarton) {
  ReactDOM.render(
    React.createElement(withErrorBoundary(ManifestCartonContainer), {
      manifestId: Number(elementCarton.dataset.manifestId),
      concludeClassificationUrl: elementCarton.dataset.concludeClassificationUrl
    }),
    elementCarton
  );
}
