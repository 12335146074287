import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-virtualized';

import ManifestCarton, {
  HEADER_GUIDE,
  HEADER_STATUS
} from './ManifestCarton';
import useSize from '../../hooks/useSize';
import useOnDesktop from '../../hooks/useOnDesktop';


export default function ManifestCartons({ cartons, listRef }) {
  const { size, elementRefToGetSize } = useSize();
  const onDesktop = useOnDesktop();

  function rowRenderer(
    {
      key,
      index,
      style
    }
  ) {
    return (
      <div
        className="container"
        style={style}
        key={key}
      >
        <ManifestCarton
          carton={cartons[index]}
        />
      </div>
    );
  }

  return (
    <div className="manifest-guides m-0 px-0 grid h-100 d-flex flex-column">
      <div className="row no-gutters grid-header">
        <div className="col-5">{HEADER_GUIDE}</div>
        <div className="col-1 text-center">{HEADER_STATUS}</div>
      </div>
      <div ref={elementRefToGetSize} className="manifest-guides-view-port h-100 w-100">
        {(size.width > 0 && size.height > 0) && (
          <List
            ref={listRef}
            width={size.width}
            height={size.height}
            rowCount={cartons.length}
            rowHeight={onDesktop ? 100 : 380}
            rowRenderer={rowRenderer}
          />
        )}
      </div>
    </div>
  );
}

ManifestCartons.propTypes = {
  cartons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      bigBag: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
      lastScanStatus: PropTypes.number.isRequired
    })
  ).isRequired,
  listRef: PropTypes.shape({ current: PropTypes.instanceOf(List) })
};
