import { normalize, schema } from 'normalizr';

const CartonEntity = new schema.Entity('cartons');

export default function normalizeGuides(response) {
  const {
    entities: { cartons }
  } = normalize(response, [CartonEntity]);
  return cartons;
}
