import {
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  useState
} from 'react';
import moment from 'moment';

import CartonApi from '../api/CartonApi';
import normalizeCartons from '../utils/normalizeCartons';
import App from '../../../app';

const reducer = (state, action) => {
  switch (action.type) {
    case 'store':
      return action.payload;

    case 'update':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload
        }
      };

    default:
      return state;
  }
};

export default function useCartons(manifestId, onScanSuccess, onScanError) {
  const [state, dispatch] = useReducer(reducer, {});
  const [error, setError] = useState();

  useEffect(() => {
    (async () => {
      try {
        const cartons = await CartonApi.all_cartons(manifestId);
        dispatch({
          type: 'store',
          payload: normalizeCartons(cartons)
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [manifestId]);

  const cartons = useMemo(
    () => Object.values(state || {})
      .sort((a, b) => moment(b.lastScanDatetime).diff(moment(a.lastScanDatetime))),
    [state]
  );

  const scans = useMemo(
    () => Object.values(state || {}).reduce(
      (sum, carton) => sum + (carton.lastScanStatus != 0 ? 1 : 0),
      0
    ),
    [state]
  );

  const onScan = useCallback(
    async (bigBag) => {
      try {
        const carton = await CartonApi.scan_carton(manifestId, bigBag);
        dispatch({ type: 'update', payload: carton });

        if (carton.last_scan_status === 0) {
          throw new Error(gettext('carton with guide with physical classification'));
        } else if (carton.last_scan_status === 1) {
          throw new Error(gettext('carton with guide with retained classification'));
        }

        if (onScanSuccess) {
          onScanSuccess();
        }
      } catch (requestError) {
        setError(requestError.message);
        if (onScanError) {
          onScanError();
        }
      } finally {
        App.utils.hideLoadingButtons();
      }
    },
    [manifestId, onScanError, onScanSuccess]
  );

  const clearError = () => setError(undefined);

  return {
    data: {
      cartons,
      scans,
      error
    },
    actions: {
      onScan,
      clearError
    }
  };
}
