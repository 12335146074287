import request, { Method } from '../../../utils/request';

export default {
  async all_cartons(manifestId) {
    return request(`manifests/${manifestId}/cartons`);
  },
  async scan_carton(manifestId, bigBag) {
    return request(`manifests/${manifestId}/cartons/${bigBag}/scan/`, Method.PUT);
  }
};
