import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

import ManifestTotal from '../ManifestTotal';

export default function ManifestCartonsHeader(
  {
    totalCartons,
    totalScans,
    concludeClassificationUrl
  }
) {
  const csrfToken = Cookies.get('csrftoken');

  return (
    <div className="row no-gutters align-items-center">
      <div className="col-sm-4">
        <ManifestTotal
          title={gettext('total of cartons')}
          total={totalCartons}
        />
      </div>
      <div className="col-sm-4">
        <ManifestTotal
          title={gettext('scans')}
          total={totalScans}
        />
      </div>
      <form
        method="POST"
        action={concludeClassificationUrl}
        className="col-sm-4 text-center text-sm-right pt-3 pt-sm-0"
      >
        <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
        <button
          type="submit"
          className="btn btn-primary"
        >
          <i className="fas fa-check mr-2" />
          {gettext('end classification')}
        </button>
      </form>
    </div>
  );
}

ManifestCartonsHeader.propTypes = {
  totalCartons: PropTypes.number.isRequired,
  totalScans: PropTypes.number,
  concludeClassificationUrl: PropTypes.string.isRequired
};
