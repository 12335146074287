import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/manifest-guide.scss';

export const HEADER_GUIDE = gettext('carton');
export const HEADER_STATUS = gettext('status');

function ManifestCarton({ carton }) {
  const percent = carton.lastScanStatus == 0 ? 0 : 100;

  const classificationClass = `classification-${carton.lastScanStatus}`;

  return (
    <div className="manifest-guide row">
      <div className="manifest-guide-back col-12">
        <div className="row grid-row">
          <div
            data-header={HEADER_GUIDE}
            className="manifest-guide-number col-lg-5"
          >
            {carton.bigBag}
          </div>
          <div
            data-header={HEADER_STATUS}
            className="col-lg-1 justify-content-lg-center"
          >
            {carton.lastScanStatus}
          </div>
        </div>
      </div>
      <div
        className={`manifest-guide-front container col-12 ${
          percent === 0 ? 'p-0' : ''
        } ${classificationClass}`}
        style={{
          width: `${percent}%`
        }}
      >
        <div className="row grid-row">
          <div
              data-header={HEADER_GUIDE}
              className="manifest-guide-number col-lg-5"
            >
              {carton.bigBag}
            </div>
            <div
              data-header={HEADER_STATUS}
              className="col-lg-1 justify-content-lg-center"
            >
              {carton.lastScanStatus}
            </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ManifestCarton);

ManifestCarton.propTypes = {
  carton: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    bigBag: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    lastScanStatus: PropTypes.number.isRequired
  }).isRequired
};
