import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Overlay from '../Overlay';
import ErrorModal from '../ErrorModal';
import ManifestCartonsHeader from './components/ManifestCarton/ManifestCartonsHeader';
import ManifestCartonActions from './components/ManifestCarton/ManifestCartonActions';
import useCartons from './hooks/useCartons';
import ManifestCartons from './components/ManifestCarton/ManifestCartons';

export default function ManifestCarton({ manifestId, concludeClassificationUrl }) {
  const actionsRef = useRef(null);
  const listRef = useRef(null);

  const {
    data: {
      cartons,
      scans,
      error
    },
    actions: {
      onScan,
      clearError
    }
  } = useCartons(
    manifestId,
    () => {
      actionsRef.current.focusScanner();
      listRef.current.scrollToPosition(0);
    },
    () => {
      actionsRef.current.blurScanner();
    }
  );

  const onCloseError = () => {
    clearError();
    actionsRef.current.focusScanner();
  };

  return (
    <div className="manifest h-100 d-flex flex-column">
      <ManifestCartonsHeader
        totalCartons={cartons.length}
        totalScans={scans}
        concludeClassificationUrl={concludeClassificationUrl}
      />
      <ManifestCartonActions ref={actionsRef} onScan={onScan} />
      <ManifestCartons cartons={cartons} listRef={listRef} />
      <Overlay show={!!error}>
        <ErrorModal
          error={error}
          onClose={onCloseError}
        />
      </Overlay>
    </div>
  );
}

ManifestCarton.propTypes = {
  manifestId: PropTypes.number.isRequired,
  concludeClassificationUrl: PropTypes.string.isRequired
};
