import React, {
  forwardRef,
  useState,
  useRef,
  useImperativeHandle
} from 'react';

// eslint-disable-next-line react/prop-types
function ManifestCartonActions({ onScan }, ref) {
  const [bigBag, setBigBag] = useState('');
  const scannerRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    onScan(bigBag);
  };

  const onChangeBigBag = (e) => {
    setBigBag(e.target.value);
  };

  const doAction = (input) => {
    input.setSelectionRange(0, input.value.length);
  };

  const stopSpecialCharacters = (e) => {
    if (e.code === 'Enter') {
      doAction(e.srcElement);
    }
    if (!/^(?:[A-Za-z0-9\-_]|Delete|Enter|Backspace|Tab)$/.test(e.key) || e.ctrlKey) {
      e.preventDefault();
    }
  };

  useImperativeHandle(ref, () => ({
    focusScanner() {
      scannerRef.current.focus();
      setBigBag('');
    },
    blurScanner() {
      scannerRef.current.blur();
    }
  }));

  return (
    <form className="row no-gutters" onSubmit={onSubmit}>
      <div className="col-sm-5 my-3">
        <div className="input-group">
          <input
            ref={scannerRef}
            type="text"
            value={bigBag}
            onChange={onChangeBigBag}
            className="form-control"
            placeholder="..."
            aria-label="..."
            aria-describedby="enter"
            onKeyDown={stopSpecialCharacters}
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary"
              type="submit"
              id="enter"
              disabled={bigBag === ''}
            >
              <i className="fas fa-barcode" />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default forwardRef(ManifestCartonActions);
